<template>
  <action-edit
    :action-url="`newsletter/subscribers/${item.id}`"
    :item="item"
    button-label="Editeaza abonat"
    dialog-width="500px"
  >
    <template v-slot:fields="{form, errors}">
      <div>
        <v-select
          v-model="form.email_validation_status"
          :items="options"
          clearable
          dense
          hide-details
          outlined
          single-line
        ></v-select>
        <error-messages :errors="errors.email_validation_status"/>
      </div>
    </template>

  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'

export default {
  components: {
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  },
  data () {
    return {
      options: [
        {
          text: 'Deliverable',
          value: 'deliverable'
        },
        {
          text: 'Undeliverable',
          value: 'undeliverable'
        },
        {
          text: 'Unknown',
          value: 'unknown'
        },
        {
          text: 'Do Not Send',
          value: 'do_not_send'
        },
        {
          text: 'Catch All',
          value: 'catch_all'
        }
      ]
    }
  }
}
</script>
